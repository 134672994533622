import { Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { Divider } from "@src/appV2/redesign/components/Divider";
import { Controller, type UseFormReturn } from "react-hook-form";

import { CalendarHeaderRow } from "../../Calendar/HeaderRow";
import { PageHeaderWrapper } from "../../components/PageHeaderWrapper";
import { type FiltersFormValues } from "../../ShiftDiscovery/Filters/BottomSheet/types";
import { ShiftTimeSlotButtonGroup } from "../../ShiftDiscovery/Filters/ShiftTimeSlotButtonGroup";
import { useShiftDiscoveryUserFiltersContext } from "../../ShiftDiscovery/Filters/useUserFiltersContext";

interface ShiftDatePickerHeaderProps {
  form: UseFormReturn<FiltersFormValues>;
}
export function ShiftDatePickerHeader(props: ShiftDatePickerHeaderProps) {
  const { form } = props;
  const { setShiftTimeSlots } = useShiftDiscoveryUserFiltersContext();

  return (
    <PageHeaderWrapper
      component="div"
      variant="tertiary"
      sx={(theme) => ({
        boxShadow: theme.shadows[3],
      })}
    >
      <Stack spacing={6} sx={{ padding: 5 }}>
        <Title variant="h4" component="h2" textAlign="center">
          Dates and Times
        </Title>

        <Controller
          control={form.control}
          name="shiftTimeSlots"
          render={({ field }) => (
            <ShiftTimeSlotButtonGroup
              value={field.value}
              onChange={(shiftTimeSlots) => {
                field.onChange(shiftTimeSlots);

                setShiftTimeSlots(shiftTimeSlots);
              }}
            />
          )}
        />
      </Stack>

      <Divider />

      <CalendarHeaderRow sx={{ px: 5 }} />
    </PageHeaderWrapper>
  );
}
