import { mergeSxProps } from "@clipboard-health/ui-react";

import { type CardProps } from "../../components/Card";
import { CardWithIntent } from "../../components/CardWithIntent";
import { ShiftType } from "../constants";

interface ShiftCardWrapperProps extends CardProps {
  shiftType: ShiftType;
}

function resolveIntent(shiftType: ShiftType) {
  if (shiftType === ShiftType.URGENT) {
    return "warning";
  }

  return undefined;
}

export function ShiftCardWrapper(props: ShiftCardWrapperProps) {
  const { shiftType, variant = "tertiary", elevation = 2, sx, ...restProps } = props;

  return (
    <CardWithIntent
      intent={resolveIntent(shiftType)}
      variant={variant}
      elevation={elevation}
      sx={mergeSxProps(
        (theme) => ({
          minHeight: theme.size!.shiftCard.minHeight,
        }),
        sx
      )}
      {...restProps}
    />
  );
}
