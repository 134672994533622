import { startOfWeek } from "@clipboard-health/date-time";
import { isDefined } from "@clipboard-health/util-ts";
import { type Facility } from "@src/appV2/Facilities/types";
import { DEFAULT_TIMEZONE } from "@src/appV2/Shifts/Shift/constants";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { differenceInSeconds, isEqual, parseISO, secondsInHour } from "date-fns";

type ShiftTime = Pick<Shift, "shiftId" | "start" | "end">;

type ShiftFacilityTime = ShiftTime & {
  facility: Pick<
    Facility,
    "tmz" | "preventDoubleShifts" | "maxAllowedWorkHoursPerWeek" | "maxAllowedWorkConsecutiveHours"
  >;
};

export interface HoursRestrictionConflictData {
  openShiftsAtSameFacility: ShiftTime[];
  bookedShiftsAtSameFacility: ShiftTime[];
}

function areShiftsConsecutive(shiftA: ShiftTime, shiftB: ShiftTime) {
  if (
    Math.abs(differenceInSeconds(parseISO(shiftA.end), parseISO(shiftB.start))) <= secondsInHour
  ) {
    return true;
  }

  return (
    Math.abs(differenceInSeconds(parseISO(shiftA.start), parseISO(shiftB.end))) <= secondsInHour
  );
}

function hasDoubleShiftConflict(shift: ShiftFacilityTime, openShiftsAtSameFacility: ShiftTime[]) {
  const {
    facility: { preventDoubleShifts },
  } = shift;

  if (!preventDoubleShifts) {
    return false;
  }

  return openShiftsAtSameFacility.some((openShift) => areShiftsConsecutive(shift, openShift));
}

function hasMaxWeeklyHoursConflict(
  shift: ShiftFacilityTime,
  openShiftsAtSameFacility: ShiftTime[],
  bookedShiftsAtSameFacility: ShiftTime[]
) {
  const {
    facility: { maxAllowedWorkHoursPerWeek, tmz },
    end,
    start,
  } = shift;

  if (!maxAllowedWorkHoursPerWeek) {
    return false;
  }

  const facilityTmz = tmz ?? DEFAULT_TIMEZONE;

  const shiftDurationHours = differenceInSeconds(parseISO(end), parseISO(start)) / secondsInHour;

  const bookedShiftsSameWeek = bookedShiftsAtSameFacility.filter((shift) => {
    const { start: shiftStart } = shift;
    return isEqual(
      startOfWeek(parseISO(shiftStart), { timezone: facilityTmz }),
      startOfWeek(parseISO(start), { timezone: facilityTmz })
    );
  });

  const bookedHoursSameWeek = bookedShiftsSameWeek.reduce((bookedHours, shift) => {
    const { start: shiftStart, end: shiftEnd } = shift;
    return (
      bookedHours + differenceInSeconds(parseISO(shiftEnd), parseISO(shiftStart)) / secondsInHour
    );
  }, 0);

  const remainingHoursThisWeekAfterBooking =
    maxAllowedWorkHoursPerWeek - bookedHoursSameWeek - shiftDurationHours;

  const openShiftsSameWeek = openShiftsAtSameFacility.filter((openShift) => {
    const { start: openShiftStart } = openShift;
    return isEqual(
      startOfWeek(parseISO(openShiftStart), { timezone: facilityTmz }),
      startOfWeek(parseISO(start), { timezone: facilityTmz })
    );
  });

  return openShiftsSameWeek.some((openShift) => {
    const { start: openShiftStart, end: openShiftEnd } = openShift;
    const openShiftDurationHours =
      differenceInSeconds(parseISO(openShiftEnd), parseISO(openShiftStart)) / secondsInHour;
    return openShiftDurationHours > remainingHoursThisWeekAfterBooking;
  });
}

function hasConsecutiveHoursConflict(
  shift: ShiftFacilityTime,
  openShiftsAtSameFacility: ShiftTime[] = [],
  bookedShiftsAtSameFacility: ShiftTime[] = []
) {
  const {
    facility: { preventDoubleShifts, maxAllowedWorkConsecutiveHours },
    end,
    start,
  } = shift;

  if (preventDoubleShifts) {
    return false;
  }

  if (!isDefined(maxAllowedWorkConsecutiveHours)) {
    return false;
  }

  const shiftDurationHours = differenceInSeconds(parseISO(end), parseISO(start)) / secondsInHour;

  const consecutiveShifts = [...openShiftsAtSameFacility, ...bookedShiftsAtSameFacility].filter(
    (s) => areShiftsConsecutive(shift, s)
  );

  return consecutiveShifts.some((consecutiveShift) => {
    const { start: openShiftStart, end: openShiftEnd } = consecutiveShift;
    const openShiftDurationHours =
      differenceInSeconds(parseISO(openShiftEnd), parseISO(openShiftStart)) / secondsInHour;

    if (shiftDurationHours + openShiftDurationHours > maxAllowedWorkConsecutiveHours) {
      return true;
    }

    return openShiftDurationHours > maxAllowedWorkConsecutiveHours;
  });
}

export function shiftHasHourRestrictionConflict(
  shift: ShiftFacilityTime,
  hoursRestrictionConflictData: HoursRestrictionConflictData
) {
  const { openShiftsAtSameFacility, bookedShiftsAtSameFacility } = hoursRestrictionConflictData;

  const openShiftsExcludingThisShift = openShiftsAtSameFacility.filter(
    (s) => s.shiftId !== shift.shiftId
  );

  const {
    facility: { preventDoubleShifts },
  } = shift;

  if (preventDoubleShifts && hasDoubleShiftConflict(shift, openShiftsExcludingThisShift)) {
    return true;
  }

  if (!preventDoubleShifts && hasConsecutiveHoursConflict(shift, openShiftsExcludingThisShift)) {
    return true;
  }

  return hasMaxWeeklyHoursConflict(shift, openShiftsExcludingThisShift, bookedShiftsAtSameFacility);
}
