import { Stack } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useWorkerPendingShiftInvites } from "@src/appV2/Shifts/ShiftInvites/api/useWorkerPendingShiftInvites";
import { minutesToMilliseconds } from "date-fns";
import { useHistory } from "react-router-dom";

import { Callout } from "../../components/Callout";

export function ShiftInvitesCallout() {
  const { data: shiftInvites, isSuccess } = useWorkerPendingShiftInvites({
    options: {
      // The shift discovery page remains mounted when opening shifts in bottom sheets,
      // preventing automatic refetches of the query on mount. Setting a stale time ensures invites
      // are periodically refreshed even without unmounting.
      staleTime: minutesToMilliseconds(2),
    },
  });

  const history = useHistory();

  const pendingShiftInvites = isSuccess ? shiftInvites.data : [];
  const shiftInviteCount = pendingShiftInvites.length;

  if (shiftInviteCount <= 0) {
    return null;
  }

  return (
    <Callout
      title={
        <Stack direction="row" alignItems="center" gap={2} component="span">
          <span>Invites</span>
          <span>{shiftInviteCount}</span>
        </Stack>
      }
      iconType="invite"
      sx={(theme) => ({
        backgroundColor: theme.palette.shift?.priority.background,
        color: theme.palette.shift?.priority.text,
      })}
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_CALLOUT_VIEWED, {
          type: "shiftInvite",
          count: shiftInviteCount,
        });
        history.push(`${RootPaths.APP_V2_HOME}/myShifts/shiftInvites`);
      }}
    />
  );
}
