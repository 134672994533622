import { isDefined } from "@clipboard-health/util-ts";
import { CardContent, Stack } from "@mui/material";
import { type ReactNode } from "react";

import { Card } from "../../components/Card";
import { WorkerShiftSummaryInfoWithDate } from "../WorkerShift/SummaryInfoWithDate";
import { type WorkerShiftSummaryInfoWithDateProps } from "../WorkerShift/SummaryInfoWithDate";

export type ShiftWithContentCardProps = {
  pills?: ReactNode[];
  children: ReactNode;
} & WorkerShiftSummaryInfoWithDateProps;

export function ShiftWithContentCard(props: ShiftWithContentCardProps) {
  const {
    pills,
    children,
    shiftTimeSlot,
    shiftStart,
    shiftEnd,
    shiftAgentReq,
    shiftHourlyPay,
    shiftTotalPay,
    workplaceName,
    workplaceTimezone,
    shiftSkippedBreakTime,
    shiftSkippedBreakTimePay,
  } = props;

  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardContent sx={{ position: "relative", padding: 6 }}>
        <Stack spacing={6}>
          {isDefined(pills) && (
            <Stack direction="row" spacing={2}>
              {...pills}
            </Stack>
          )}
          <WorkerShiftSummaryInfoWithDate
            shiftTimeSlot={shiftTimeSlot}
            shiftStart={shiftStart}
            shiftEnd={shiftEnd}
            shiftAgentReq={shiftAgentReq}
            shiftHourlyPay={shiftHourlyPay}
            shiftTotalPay={shiftTotalPay}
            workplaceName={workplaceName}
            workplaceTimezone={workplaceTimezone}
            shiftSkippedBreakTime={shiftSkippedBreakTime}
            shiftSkippedBreakTimePay={shiftSkippedBreakTimePay}
          />
          {children}
        </Stack>
      </CardContent>
    </Card>
  );
}
