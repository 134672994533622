import { isDefined } from "@clipboard-health/util-ts";
import { type DateRange } from "@src/appV2/lib";
import { convertToGeoLocation } from "@src/appV2/Location";
import { type OpenShiftCountResponse } from "@src/appV2/OpenShifts/api/useOpenShiftCount";
import { usePaginatedOpenShiftCount } from "@src/appV2/OpenShifts/api/usePaginatedOpenShiftCount";
import { usePaginatedWorkerShifts } from "@src/appV2/OpenShifts/api/usePaginatedWorkerShifts";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { differenceInMonths, minutesToMilliseconds, startOfMonth } from "date-fns";
import { useState } from "react";

import { DEFAULT_DISTANCE_IN_MILES_FILTER } from "../../ShiftDiscovery/Filters/constants";
import { filterOpenShiftsCount } from "../../ShiftDiscovery/Filters/filterOpenShiftsCount";
import { useShiftDiscoveryUserFiltersContext } from "../../ShiftDiscovery/Filters/useUserFiltersContext";
import { getShiftDiscoveryDefaultDateRange } from "../../utils/getShiftDiscoveryDefaultDateRange";
import { groupShiftsByDateAndTimeSlot } from "../../utils/groupShiftsByDateAndTimeSlot";
import { useGetOpenShiftsForDates } from "../Open/useGetOpenShiftsForDates";
import { getCalendarPickerDateRange } from "./getCalendarPickerDateRange";
import { mergeUrgentShiftsWithCountData } from "./mergeUrgentShiftsWithCountData";
import { type OpenShiftCountResponseWithUrgent } from "./types";

interface UseShiftDiscoveryCalendarDataProps {
  initialDateRange: DateRange;
}

export const useShiftDiscoveryCalendarData = (props: UseShiftDiscoveryCalendarDataProps) => {
  const { initialDateRange } = props;

  const worker = useDefinedWorker();

  const location = convertToGeoLocation(worker.geoLocation?.coordinates ?? [0, 0]);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { distance, shiftTimeSlots } = useShiftDiscoveryUserFiltersContext();

  /**
   * This is a total date range used within the consumer of this hook.
   * If `loadMore` is called, this date range will be extended to the next end range.
   */
  const [dateRange, setDateRange] = useState<DateRange>(initialDateRange);

  const {
    data: workerShiftsData,
    isSuccess: workerShiftsIsSuccess,
    isLoading: workerShiftsIsLoading,
    refetch: refetchWorkerShifts,
    fetchNextPage: fetchMoreWorkerShifts,
    isFetchingNextPage: workerShiftsIsFetchingNextPage,
  } = usePaginatedWorkerShifts({
    initialDateRange: {
      startDate: startOfMonth(initialDateRange.startDate),
      endDate: initialDateRange.endDate,
    },
    groupByDate: false,
    tmz: timezone ?? "",
  });

  const workerShiftsByDate = workerShiftsIsSuccess
    ? groupShiftsByDateAndTimeSlot(
        workerShiftsData.pages.flatMap((page) => page.agentShifts) ?? [],
        timezone
      )
    : {};

  const {
    data: paginatedOpenShiftsCountData,
    isLoading: openShiftsCountIsLoading,
    refetch: refetchOpenShiftsCount,
    fetchNextPage: fetchMoreOpenShiftsCount,
    isFetchingNextPage: openShiftsCountIsFetchingNextPage,
    // TODO: open shifts count endpoint doesn't support taking multiple licenses/qualifications,
    // and the result might not be accurate with the /open-shifts endpoint.
    // To be addressed in this ticket: https://linear.app/clipboardhealth/issue/SPRTA-1049/implement-get-time-slot-availabilities
  } = usePaginatedOpenShiftCount(
    {
      initialDateRange,
      coordinates: worker.geoLocation?.coordinates,
      specialities: {
        hasSedationExperience: worker.specialities?.hasSedationExperience ?? false,
        hasTrayAssemblyExperience: worker.specialities?.hasTrayAssemblyExperience ?? false,
      },
      distance,
      tmz: timezone ?? "",
      isAgent: true,
    },
    {
      enabled: isDefined(timezone) && isDefined(worker?.preference),
      // We don't want to refetch open shift count data frequently as it can cause bad UX and performance issues.
      staleTime: minutesToMilliseconds(5),
    }
  );

  /**
   * The above call for usePaginatedOpenShiftCount does not support fetching urgent shifts, so we need to fetch them separately.
   * These open shifts are used to determine if a day has urgent shifts by merging them with the open shifts count data.
   * See `mergeUrgentShiftsWithCountData` for more details.
   */
  const {
    shifts: urgentShifts,
    pagination: urgentShiftsPagination,
    isLoading: urgentShiftsIsLoading,
  } = useGetOpenShiftsForDates({
    enabled: worker.geoLocation?.coordinates.length === 2,
    dates: [dateRange.startDate, dateRange.endDate],
    dateRange: getShiftDiscoveryDefaultDateRange(),
    filter: {
      isUrgent: true,
      area: {
        latitude: location.latitude,
        longitude: location.longitude,
        radiusInMiles: DEFAULT_DISTANCE_IN_MILES_FILTER,
      },
    },
  });

  const unfilteredOpenShiftsCountData =
    paginatedOpenShiftsCountData?.pages.reduce<OpenShiftCountResponse>((result, page) => {
      return {
        ...result,
        ...page.data,
      };
    }, {}) ?? {};

  const unfilteredOpenShiftsCountDataWithUrgency = mergeUrgentShiftsWithCountData(
    unfilteredOpenShiftsCountData,
    urgentShifts
  );

  const filteredOpenShiftsCountData: OpenShiftCountResponseWithUrgent = filterOpenShiftsCount(
    unfilteredOpenShiftsCountDataWithUrgency,
    {
      shiftTimeSlots,
    }
  );

  const loadMore = async () => {
    const nextDateRangeToFetch = getCalendarPickerDateRange(dateRange.endDate);

    await Promise.all([
      fetchMoreWorkerShifts({
        pageParam: nextDateRangeToFetch,
      }),

      fetchMoreOpenShiftsCount({
        pageParam: nextDateRangeToFetch,
      }),

      urgentShiftsPagination?.fetchNextPage({
        pageParam: nextDateRangeToFetch,
      }),
    ]);

    setDateRange({
      startDate: dateRange.startDate,
      endDate: nextDateRangeToFetch.endDate,
    });
  };

  const refetch = () => {
    void refetchWorkerShifts();
    void refetchOpenShiftsCount();
  };

  return {
    dateRange,
    setDateRange,
    workerShiftsByDate,
    workerShiftsIsLoading,
    workerShiftsIsFetchingNextPage,
    unfilteredOpenShiftsCountData: unfilteredOpenShiftsCountDataWithUrgency,
    filteredOpenShiftsCountData,
    openShiftsCountIsLoading,
    openShiftsCountIsFetchingNextPage,
    urgentShifts,
    urgentShiftsIsLoading,
    refetch,
    loadMore,
    canLoadMore:
      isDefined(dateRange.endDate) &&
      differenceInMonths(dateRange.endDate, dateRange.startDate) < 12,
  };
};
