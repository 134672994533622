import { endOfWeek, startOfWeek } from "@clipboard-health/date-time";
import { isDefined } from "@clipboard-health/util-ts";
import { type DateRange } from "@src/appV2/lib";
import { convertToGeoLocation } from "@src/appV2/Location";
import { useAgentShifts } from "@src/appV2/OpenShifts/api/useAgentShifts";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { addDays, parseISO, subDays } from "date-fns";

import { DEFAULT_DISTANCE_IN_MILES_FILTER } from "../../../ShiftDiscovery/Filters/constants";
import { useGetOpenShifts } from "../../Open/useGetOpenShifts";
import { type HoursRestrictionConflictData } from "../shiftHasHourRestrictionConflict";

export interface UseHourRestrictionConflictDataProps {
  shiftStart?: string;
  workplaceId?: string;
}

export interface UseHourRestrictionConflictDataOptions {
  enabled?: boolean;
}

export function useHourRestrictionConflictData(
  props: UseHourRestrictionConflictDataProps,
  options: UseHourRestrictionConflictDataOptions = {}
) {
  const { workplaceId, shiftStart } = props;
  const { enabled } = options;

  const worker = useDefinedWorker();
  const shiftDate = shiftStart ? parseISO(shiftStart) : new Date();

  const bookedShiftsDateRange: DateRange = {
    startDate: subDays(startOfWeek(shiftDate, { timezone: worker.tmz }), 1),
    endDate: addDays(endOfWeek(shiftDate, { timezone: worker.tmz }), 1),
  };

  const coordinates = convertToGeoLocation(worker.geoLocation?.coordinates ?? [0, 0]);

  const {
    data: openShiftsAtSameFacilityResponse,
    isError: openShiftsAtSameFacilityIsError,
    isLoading: openShiftsAtSameFacilityIsLoading,
  } = useGetOpenShifts(
    {
      filter: {
        "workplace.id": workplaceId,
        start: {
          gte: bookedShiftsDateRange.startDate.toISOString(),
          lte: bookedShiftsDateRange.endDate.toISOString(),
        },
        area: {
          latitude: coordinates.latitude,
          longitude: coordinates.longitude,
          radiusInMiles: DEFAULT_DISTANCE_IN_MILES_FILTER,
        },
      },
    },
    {
      enabled: enabled && isDefined(shiftStart) && isDefined(workplaceId),
    }
  );

  const {
    data: agentShiftsData,
    isLoading: agentShiftsIsLoading,
    isError: agentShiftsIsError,
  } = useAgentShifts(
    {
      startDate: bookedShiftsDateRange.startDate,
      endDate: bookedShiftsDateRange.endDate,
      tmz: worker.tmz,
      groupByDate: false,
    },
    {
      enabled: enabled && isDefined(shiftStart),
    }
  );

  const bookedShiftsAtSameFacility = agentShiftsData?.agentShifts.filter(
    (s) => s.facilityId === workplaceId
  );

  const isLoading = openShiftsAtSameFacilityIsLoading || agentShiftsIsLoading;

  let data: HoursRestrictionConflictData | undefined;

  if (
    !isLoading &&
    isDefined(openShiftsAtSameFacilityResponse) &&
    isDefined(bookedShiftsAtSameFacility)
  ) {
    data = {
      openShiftsAtSameFacility: openShiftsAtSameFacilityResponse?.data.map((openShift) => ({
        shiftId: openShift.id,
        start: openShift.attributes.start,
        end: openShift.attributes.end,
      })),
      bookedShiftsAtSameFacility,
    };
  }

  return {
    data,
    isLoading,
    isError: openShiftsAtSameFacilityIsError || agentShiftsIsError,
  };
}
